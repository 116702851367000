html, body, .App, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iframe {
  display: block;
  width: 100%;
  height: 100% !important;
}
